//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./utils.issue-viewer.ts"  />
//imports-end

module Utils.ActionWindow {
    let $win;
    let $overlay;

    let automaticWorkflows: New.Checkpoints.Workflow.Response.IssueResponseBase[];
    let affectedRecorditem: Model.Recorditem;
    let affectedElement: Model.Elements.Element;
    let affectedIssue: Model.Issues.Issue;
    let actions: Array<any>;
    let correctiveActions: Array<any>;
    let onSaveSingleIssue: Function;
    let onSave: Function;
    let onAfterClosed: Function;
    let issuesAreTemporary: boolean;
    let actionWindowQueue: Array<Settings>;
    let closeDeferred: Deferred;

    export type Action = { IsIssue: boolean, Issue: Model.Issues.Issue | Model.Issues.RawIssue };
    export type ActionEx = Action & { OID?: string, IsTemporary?: boolean, State?: Enums.ActionState };

    export class Settings {
        AutomaticWorkflows?: Array<New.Checkpoints.Workflow.Response.IssueResponseBase>;
        Recorditem: Model.Recorditem;
        Issue: Model.Issues.Issue;
        PredefinedCorrectiveActions: Array<any>;
        ShowCorrectiveIssuesOnly?: boolean;
        IssuesAreTemporary?: boolean;

        OnAfterClosed: Function;
        OnAfterCorrectiveIssueSaved: Function;
        OnAfterCorrectiveIssuesSaved?: Function;
        ActionWindowCallback?: Function;
    }

    function destroy(): void {
        affectedRecorditem = null;
        affectedElement = null;
        actions = null;

        if (!issuesAreTemporary) {
            correctiveActions = null;
        }

        if ($win) {
            $win.remove();
            $win = null;
        }

        if ($overlay) {
            Utils.Overlay.DestroyWithTimeout($overlay);
            $overlay = null;
        }

        $('body')
            .removeClass('modal-open')
            .css('padding-right', 0);

        onSaveSingleIssue = null;
        onSave = null;

        if (closeDeferred) {
            closeDeferred.resolve();
        }

        closeDeferred = null;
    }

    function onIssueClosed(issue: Model.Issues.Issue): void {
        if (!issue.AssignedActionOID) {
            $win.find('li[data-oid="{0}"]'.format(issue.PrecedingOID || issue.OID)).remove();

            if (!!issue.PrecedingOID) {
                resetActionState(issue.PrecedingOID);
            }

            Utils.ResizeModalWindow($win);
        } else {
            $win.find('li[data-oid="{0}"]'.format(issue.AssignedActionOID))
                .removeAttr('data-issueoid')
                .data('issueoid', '');

            resetActionState(issue.AssignedActionOID);
        }

        if (onSaveSingleIssue) {
            onSaveSingleIssue(Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) ?
                affectedElement.RevisionOID :
                affectedElement.OID, issue);
        }
    }

    function onCorrectionIssueButtonClick(evt: Event): void {
        const actionOID = $(this).parent().data('oid');
        const action = Utils.Where(correctiveActions, 'OID', '===', actionOID);

        if (!action.IsTemporary) {
            const closedState = DAL.Properties.GetByOID(Session.Client.Settings.TicketCompleted);

            Utils.Message.Show(i18next.t('CorrectiveActions.CloseAction.QuestionHeader'),
                i18next.t('CorrectiveActions.CloseAction.Question', { StateTitle: closedState.Title }),
                {
                    Yes: function() {
                        Utils.IssueViewer.FinishIssue(action.Issue, onIssueClosed);
                    },
                    No: true
                }, null, 1054);
        } else {
            Utils.Message.Show(i18next.t('CorrectiveActions.DeleteTemporaryAction.QuestionHeader'),
                i18next.t('CorrectiveActions.DeleteTemporaryAction.Question'),
                {
                    Yes: function() {
                        correctiveActions.splice(Utils.Where(correctiveActions, 'OID', '===', action.Issue.OID, true), 1);

                        onIssueClosed(action.Issue);
                    },
                    No: true
                }, null, 1054);
        }

        evt.stopPropagation();
    }

    function onTriggeredActionClick(): void {
        const $li = $(this);
        const actionOID = $li.data('oid');
        const action = Utils.Where(correctiveActions, 'OID', '===', actionOID);

        if (Utils.InArray([Enums.ActionState.ApplyComment, Enums.ActionState.TakePhoto], $li.data('state'))) {
            return;
        }

        Utils.Message.Show(i18next.t('CorrectiveActions.RevertAction.QuestionHeader'),
            i18next.t('CorrectiveActions.RevertAction.Question'),
            {
                Yes: function() {
                    if (action.Issue) {
                        Utils.IssueViewer.FinishIssue(action.Issue, function(issue) {
                            resetActionState(action.OID);

                            delete action.Issue;
                            delete action.AdditionalFiles;

                            if (onSaveSingleIssue) {
                                onSaveSingleIssue(Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) ?
                                    affectedElement.RevisionOID :
                                    affectedElement.OID, issue);
                            }
                        });
                    } else {
                        resetActionState(action.OID);
                    }
                },
                No: true
            }, null, 1054);
    }

    function onIssueSaved(targetObject: Model.Issues.Issue | {
        Issue: Model.Issues.Issue,
        AdditionalFiles: Dictionary<any>
    }, sourceIssue?: Model.Issues.Issue): void {
        const issue = <Model.Issues.Issue>targetObject;
        let action = getAction(issue.AssignedActionOID);

        if (issue && affectedRecorditem) {
            issue.AssignedRecorditemID = affectedRecorditem.ID;
        }

        if (action) {
            action.Issue = issue;
            action.State = action.ActionState;

            $win.find('li[data-oid="{0}"]'.format(issue.AssignedActionOID))
                .data('state', action.ActionState).attr('data-state', action.ActionState)
                .data('issueoid', issue.ID).attr('data-issueoid', issue.OID);
        } else {
            const issueInfo = <any>targetObject;
            action = {
                IsIssue: true,
                IsTemporary: issuesAreTemporary,
                Issue: issuesAreTemporary ? issueInfo.Issue : issue,
                OID: issuesAreTemporary ? issueInfo.Issue.OID : issue.OID,
                State: Enums.ActionState.Done
            };

            const $tmp = $(Templates.CorrectiveActions.ListItem(action));
            $tmp.data('state', Enums.ActionState.Done).attr('data-state', Enums.ActionState.Done);

            $win.find('.list-wrapper > ul > li:not([data-oid])').before($tmp);

            if (issuesAreTemporary && Utils.HasProperties(issueInfo.AdditionalFiles)) {
                action.AdditionalFiles = issueInfo.AdditionalFiles;
            }
        }

        correctiveActions.push(action);
        createInfoText();

        Utils.ResizeModalWindow($win);

        // Datenübernmahme für Formular bei Bedarf durchführen
        CopyValuesFromIssue(issue, sourceIssue, action)
            .then(() => {
                if (!onSaveSingleIssue) {
                    return;
                }

                onSaveSingleIssue(Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) ?
                    affectedRecorditem.ElementRevisionOID :
                    affectedRecorditem.ElementOID, action.Issue, affectedRecorditem.Row);
            });
    }

    function onFormIssueCreated(issue: Model.Issues.Issue, sourceIssue?: Model.Issues.Issue): void {
        const action = getAction(issue.AssignedActionOID);

        if (!(issue.Resubmissionitems || []).length) {
            Utils.Message.Show(i18next.t('Forms.RecordingImpossible.MessageHeader'),
                i18next.t('Forms.NoResubitems.MessageBody'),
                {
                    Close: true
                }, null, 1054);

            return;
        }

        // Datenübernahme bei Bedarf aus Formular über Identcodes
        CopyValuesFromIssue(issue, sourceIssue, action)
            .then(() => {
                correctiveActions.push(action);
                createInfoText();

                destroy();

                Utils.Router.PushState('issue/{0}?type={1}'.format((issue.ID || issue.OID), issue.Type));
            });
    }

    export function CopyValuesFromIssue(targetIssue: Model.Issues.Issue, sourceIssue: Model.Issues.Issue, action): Deferred {
        // Bisher nur Datenübernahme über Identcodes unterstützt
        if (!action || action.DataTransferMode !== Enums.DataTransferMode.ByIdentcode) {
            return $.Deferred().resolve();
        }

        if (!sourceIssue || !targetIssue ||
            !targetIssue.AssignedFormOID ||
            !sourceIssue.Recorditems || !sourceIssue.Recorditems.length ||
            !targetIssue.Resubmissionitems || !targetIssue.Resubmissionitems.length) {
            return $.Deferred().resolve();
        }

        Utils.Spinner.Show();
        const spinLock = Utils.Spinner.Lock('copyValuesFromIssue');

        return (function() {
            // Zieldaten prüfen
            const targetResubmissions: { ResubmissionItem: Model.Issues.ResubmissionItem, Element: Model.Elements.Element }[] = [];

            for (const r of targetIssue.Resubmissionitems) {
                const element = DAL.Elements.GetByRevisionOID(r.ElementRevisionOID);

                if (!element || element.Identcode == null) {
                    continue;
                }

                targetResubmissions.push({
                    ResubmissionItem: r,
                    Element: element
                });
            }

            if (!targetResubmissions.length) {
                return $.Deferred().reject();
            }

            // Übernahmefähige Daten ermitteln
            const applicableRecorditems: { Value: any, Row?: number, Type: Enums.ElementType, Identcode: string }[] = [];

            for (const r of sourceIssue.Recorditems) {
                const element = ParameterList.GetElementRevisionByRevisionOID(r.ElementRevisionOID) || DAL.Elements.GetByOID(r.ElementOID);

                if (!element || element.Identcode == null) {
                    continue;
                }

                applicableRecorditems.push({
                    Value: r.Value,
                    Row: r.Row || null,
                    Type: element.Type,
                    Identcode: element.Identcode
                });
            }

            if (!applicableRecorditems.length) {
                return $.Deferred().reject();
            }

            // Datenübernahme durchführen
            const batchValues: RecorditemEditor.SaveOptions[] = [];
            for (const record of applicableRecorditems) {
                // finde ziel PP
                const fillableTargetCPs = targetResubmissions.filter((tr) =>
                    tr.Element.Identcode == record.Identcode &&
                    record.Type == tr.Element.Type &&
                    (record.Row == null || record.Row === tr.ResubmissionItem.Row)
                );

                if (!fillableTargetCPs.length) {
                    continue;
                }

                for (const targetCheckpoint of fillableTargetCPs) {
                    batchValues.push({
                        ResubmissionItemOID: targetCheckpoint.ResubmissionItem.OID,
                        Element: targetCheckpoint.Element,
                        PreviousRecorditem: null,
                        Row: record.Row || targetCheckpoint.ResubmissionItem.Row,
                        Issue: targetIssue,
                        Value: record.Value,
                        IgnoreValueFromUI: true
                    });
                }
            }

            return $.Deferred().resolve(batchValues);
        })().then((batchValues: RecorditemEditor.SaveOptions[]) => {
            return Utils.RecorditemEditor.SaveBatchValues(batchValues);
        }, () => { /* Nicht entfernen! - Ergebnis wird ins positive gewandelt */ }
        ).then((recorditems: Model.Recorditem[]) => {
            if (Session.IsSmartDeviceApplication && recorditems && recorditems.length) {
                // Issue mit aktualisierten Erfassungen in Datenbank speichern
                return window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, targetIssue.OID)
                    .then((newIssue: Model.Issues.RawIssue) => {
                        recorditems = recorditems.map(r => r.GetRawEntity());
                        newIssue.Recorditems = newIssue.Recorditems ? newIssue.Recorditems.concat(recorditems) : recorditems;
                        targetIssue.Recorditems = newIssue.Recorditems;

                        return DAL.Issues.SaveToDatabase(<any>(targetIssue.CopyRaw ? targetIssue.CopyRaw() : targetIssue), !!targetIssue.CopyRaw);
                    });
            }
        }).always(function() {
            spinLock.Unlock();
            Utils.Spinner.HideWithTimeout();
        });
    }

    function onActionButtonClick(): void {
        const $btn = $(this);
        const $li = $btn.parents('li');
        const oid = $li.data('oid');
        const btnActionState: Enums.ActionState = $btn.data('action');


        if (btnActionState == Enums.ActionState.EditIssue) {
            const issueOID = $li.data('issueoid');
            if (!issueOID) {
                return;
            }

            DAL.Issues.GetByOID(issueOID)
                .then((issue: Model.Issues.Issue) => {

                    if (!issue) {
                        return;
                    }

                    const locationIdentifier = issue ? issue.AssignedElementOID : null;

                    if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue, true, locationIdentifier)) {
                        Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                            i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                            {
                                Close: true
                            }, null, 1054);

                        return;
                    }

                    if (issue.Type === Enums.IssueType.Form) {
                        destroy();
                    }

                    Utils.Router.PushState('issue/{0}?type={1}'.format((issue.ID || issue.OID), issue.Type));
                })

            return;
        }

        const action = btnActionState === Enums.ActionState.CreateIssue ?
            { Type: Enums.CheckpointWorkflowType.CorrectiveAction } :
            getAction(oid);

        if (!action) {
            return;
        }

        if (btnActionState === Enums.ActionState.ApplyComment) {
            // show Edit box
            Utils.InputWindow.Show(i18next.t('CorrectiveActions.Buttons.ApplyComment'), null, {
                OK: {
                    Caption: i18next.t('Misc.Okay'),
                    Fn: (text: string) => {
                        if (!!(text || '').trim()) {
                            const commentDeferred = createNewComment(text, $li);

                            if (Session.Settings.AskIfCreateIssueAfterTextComment) {
                                commentDeferred.then(function(comment: Model.Comment) {
                                    return Utils.RecorditemEditor.AskWhetherToReUseCommentInCorrectiveAction(comment);
                                }).then(function() {
                                    return Utils.RecorditemEditor.CreateNewCorrectiveAction(null, null, affectedRecorditem, affectedElement, affectedRecorditem.AdditionalFiles, null, text);
                                });
                            }
                        }
                    }
                },
                Abort: {
                    Caption: i18next.t('Misc.Abort')
                }
            }, Utils.UnescapeHTMLEntities(action.Text), 'textarea', 1055);

            return;
        }

        if (btnActionState === Enums.ActionState.CreateIssue && Session.LastKnownAPIVersion >= 7) {
            const locationIdentifier = affectedIssue ? affectedIssue.AssignedElementOID : null;

            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue, true, locationIdentifier)) {
                Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                    i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                    {
                        Close: true
                    }, null, 1054);

                return;
            }
        }

        action.ActionState = btnActionState;

        let locationIdentifier = Session.CurrentLocation.OID;

        if (affectedIssue && affectedIssue.Type === Enums.IssueType.Form) {
            locationIdentifier = affectedIssue.AssignedElementOID;

            if (affectedIssue && affectedIssue.AssignedFormRevisionOID) {
                const form = ParameterList.GetElementRevisionByRevisionOID(affectedIssue.AssignedFormRevisionOID);
                action.InheritResponsibilities = form && form.AdditionalSettings && form.AdditionalSettings.BequeathResponsibilities;
            }
        }

        if (Utils.InArray([Enums.ActionState.Postpone, Enums.ActionState.CreateIssue], btnActionState)) {
            if (!hasIssueActionRights(action, locationIdentifier)) {
                return;
            }

            Utils.IssueViewer.CreateCorrectiveAction(affectedRecorditem,
                affectedElement,
                affectedIssue,
                action,
                issuesAreTemporary,
                (issue: Model.Issues.Issue) => { onIssueSaved(issue, affectedIssue); },
                false);
        } else if (btnActionState === Enums.ActionState.StartAcquisition) {
            if (!hasIssueActionRights(action, locationIdentifier)) {
                return;
            }

            Utils.IssueViewer.CreateCorrectiveAction(affectedRecorditem,
                affectedElement,
                affectedIssue,
                action,
                issuesAreTemporary,
                (issue: Model.Issues.Issue) => { onFormIssueCreated(issue, affectedIssue) },
                false);
        } else if (btnActionState === Enums.ActionState.SelectPhoto) {
            Utils.GetImagesFromGallery()
                .then(function(imagePaths: string[]) {
                    if (!(imagePaths instanceof Array) || !imagePaths.length) {
                        return;
                    }

                    onAfterGotImagePath(imagePaths[0], action);
                });
        } else if (btnActionState === Enums.ActionState.TakePhoto) {
            if (Session.IsSmartDeviceApplication) {
                takeAdditionalImage(action);
            }
        } else if (btnActionState === Enums.ActionState.ApplyModifications) {
            if (affectedRecorditem && affectedElement && affectedIssue) {
                const form = !!affectedIssue.AssignedFormRevisionOID ?
                    ParameterList.GetElementRevisionByRevisionOID(affectedIssue.AssignedFormRevisionOID) :
                    null;

                const workflowSettings: New.Checkpoints.Workflow.IssueWorkflowSettings = {
                    Recorditem: affectedRecorditem,
                    Checkpoint: affectedElement,
                    LocationIdentifier: affectedIssue.AssignedElementOID,
                    CreateTemporaryIssue: false,
                    ParentIssue: affectedIssue,
                    InheritResponsibilities: form && form.AdditionalSettings && form.AdditionalSettings.BequeathResponsibilities
                };

                const workflow = new New.Checkpoints.Workflow.ModifyParentIssueWorkflow(action, workflowSettings);

                Utils.Spinner.Show();

                workflow
                    .Execute()
                    .then((workflowResponse: New.Checkpoints.Workflow.Response.ModifyParentIssueWorkflow) => {
                        Utils.Spinner.Hide();

                        if (workflowResponse.Issue) {
                            IssueView.UpdateIssue(workflowResponse.Issue);
                        }

                        $li.data('state', btnActionState).attr('data-state', btnActionState);
                        correctiveActions.push(action);
                    }, Utils.Spinner.Hide);

            }
        } else {
            $li.data('state', btnActionState).attr('data-state', btnActionState);
            correctiveActions.push(action);
            createInfoText();
        }
    }

    function hasIssueActionRights(action, locationIdentifier: string): boolean {
        // Aufgabe und Formulare differenzieren
        const issueType =
            action.Type == Enums.CheckpointWorkflowType.Form ?
                Enums.IssueType.Form :
                Enums.IssueType.Task;

        if (!Utils.CanUserCreateIssueType(issueType, locationIdentifier)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                {
                    Close: true
                }, null, 1054);

            return false;
        }

        // Standard-Initialstatus ermitteln und prüfen
        let initialStateOID: string;
        if (issueType == Enums.IssueType.Form) {
            const form = action.FormOID ? DAL.Elements.GetByOID(action.FormOID) : null;

            if (form) {
                initialStateOID = form.InitialStateOID;
            }

            if (!initialStateOID) {
                initialStateOID = Session.Client.Settings.FormOpened;
            }
        } else {
            initialStateOID = Session.Client.Settings.TicketOpened;
        }

        if (!Utils.IsUserAbleToSetInitialState(locationIdentifier, initialStateOID)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.DefaultState.MessageBody'),
                {
                    Close: true
                }, null, 1054);

            return false;
        }

        return true;
    }

    function onAfterCommentSaved(comment: Model.Comment, $li): void {
        if (affectedRecorditem) {
            affectedRecorditem.Comments = affectedRecorditem.Comments || [];
            affectedRecorditem.Comments.push(comment);
        }

        onRecorditemUpdated();

        if ($li) {
            $li
                .data('state', Enums.ActionState.ApplyComment)
                .attr('data-state', Enums.ActionState.ApplyComment);
        }
    }

    function onFileInput(evt: Event): void {
        if (typeof FileReader === 'undefined') {
            return;
        }

        const $input = $(this);
        const fileInput = evt.currentTarget as HTMLInputElement;

        if (!fileInput || !(fileInput.files || []).length) {
            return;
        }

        const file: File = fileInput.files[0];

        if (!file || !Utils.IsImage(file.type)) {
            return;
        }

        Utils.Spinner.Show();

        Utils.ReadFile(file, uuid())
            .then(onAfterFileRead);

        $input.val('');
    }

    function onAfterFileRead(fileInformation): void {
        if (!fileInformation ||
            !fileInformation.File ||
            !fileInformation.Identifier) {
            return;
        }

        affectedRecorditem.AdditionalFiles = affectedRecorditem.AdditionalFiles || [];

        const file = fileInformation.File;
        const filename = fileInformation.Identifier + '.' + file.name.split('.').pop();
        const uri = `${Session.BaseURI}records/images/${filename}${Utils.GetAuthQueryParameter('?')}`;
        const mimeType = file.type || Enums.MimeType.Image;
        const fileProperties = {
            Filename: filename,
            OID: fileInformation.Identifier,
            MimeType: mimeType,
            IsImage: Utils.IsImage(mimeType),
            Content: fileInformation.Event.target.result,
            Position: affectedRecorditem.AdditionalFiles.length + 1
        };

        affectedRecorditem.AdditionalFiles.push(fileProperties);

        affectedRecorditem.SetOnAfterSavedHandler(function() {
            affectedRecorditem.RemoveOnAfterSavedHandler();

            if (!Session.IsSmartDeviceApplication) {
                Utils.UploadFile(uri, file)
                    .then(function() {
                        onAfterImageSaved(fileProperties);
                        onRecorditemUpdated();
                    });
            } else {
                return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.Recorditems, affectedRecorditem)
                    .then(onRecorditemUpdated);
            }
        });

        affectedRecorditem.Save();
    }

    function onAfterImageSaved(fileProperties): void {
        createInfoText();
        addAdditionalImageToList(fileProperties);

        // fileProperties werden als Array übergebene, damit die Abfrage nicht vorzeitig beendet wird
        Utils.RecorditemEditor.AskWhetherToReUseFilesInCorrectiveAction([fileProperties])
            .then(function() {
                createNewCorrectiveActionFromPhoto(null, fileProperties);
            })

        Utils.Spinner.Hide();
    }

    function onCorrectiveIssueActionSaved(issueInformation): void {
        let action;

        if (issuesAreTemporary) {
            action = Utils.Where(correctiveActions, 'OID', '===', issueInformation.Issue.OID);
            action.OID = issueInformation.Issue.OID;
            action.Issue = issueInformation.Issue;

            if (Utils.HasProperties(issueInformation.AdditionalFiles)) {
                action.AdditionalFiles = issueInformation.AdditionalFiles;
            }

            $win.find('li[data-oid="{0}"]'.format(action.OID)).replaceWith(Templates.CorrectiveActions.ListItem(action));
        } else {
            action = Utils.Where(correctiveActions, 'OID', '===', issueInformation.PrecedingOID);
            action.OID = issueInformation.OID;
            action.Issue = issueInformation;

            $win.find('li[data-oid="{0}"]'.format(issueInformation.PrecedingOID)).replaceWith(Templates.CorrectiveActions.ListItem(action));

            Utils.Spinner.Hide();
        }

        if (onSaveSingleIssue) {
            onSaveSingleIssue(action.Issue);
        }
    }

    function onCorrectiveActionIssueClick(): void {
        const oid = $(this).data('oid');
        const correctiveAction = Utils.Where(correctiveActions, 'OID', '===', oid);

        if (correctiveAction) {
            if (issuesAreTemporary) {
                let locationIdentifier = Session.CurrentLocation.OID;

                if (affectedIssue && affectedIssue.Type === Enums.IssueType.Form) {
                    locationIdentifier = affectedIssue.AssignedElementOID;
                }

                if (!Utils.IsUserAbleToSetInitialState(locationIdentifier, Session.Client.Settings.TicketOpened)) {
                    Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                        i18next.t('Misc.RightError.DefaultState.MessageBody'),
                        {
                            Close: true
                        }, null, 1054);

                    return;
                }

                Utils.IssueViewer.OpenTemporaryIssue(
                    $.extend(true, {}, correctiveAction.Issue),
                    correctiveAction.AdditionalFiles,
                    onCorrectiveIssueActionSaved);
            } else {
                Utils.IssueViewer.OpenIssue(oid, onCorrectiveIssueActionSaved);
            }
        }
    }

    function onRecorditemUpdated(): void {
        ParameterList.UpdateElementCell(affectedRecorditem);
    }

    function onBtnCloseClick(): void {
        const tmpAffectedElement = affectedElement;

        destroy();

        if (onAfterClosed instanceof Function) {
            onAfterClosed(tmpAffectedElement);

            onAfterClosed = null;
        }
    }

    function onBtnApplyClick(): void {
        onSave(correctiveActions);

        onSave = null;
        correctiveActions = null;
    }

    function onAfterGotImagePath(filePath: string, selectedCorrectiveAction): Deferred {
        affectedRecorditem.AdditionalFiles = affectedRecorditem.AdditionalFiles || [];

        const oid = uuid();
        const filename = oid + '.' + filePath.split('.').pop();
        const file = {
            Filename: filename,
            OID: oid,
            MimeType: Enums.MimeType.Image,
            IsImage: true,
            Position: affectedRecorditem.AdditionalFiles.length + 1
        };

        affectedRecorditem.AdditionalFiles.push(file);

        return Utils.MoveFileToResources(filePath, filename)
            .then(() => {
                const syncEntity = new Model.Synchronisation.RecordItemFileEntityDescription(
                    filename, Enums.SyncEntityType.RecorditemAdditionalFile, affectedRecorditem
                );

                return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, syncEntity)
            })
            .then(() => {
                correctiveActions.push(selectedCorrectiveAction);

                affectedRecorditem.IsUpdate = true;
                affectedRecorditem.Save();

                onAfterImageSaved(file);
            });
    }

    function onAdditionalImageClick(): void {
        const filename = $(this).data('filename');
        const file = Utils.Where(affectedRecorditem.AdditionalFiles, 'Filename', '===', filename);

        function onMarksSaved(_fileIdentifier: string, marks: string) {
            if (file) {
                file.Marks = marks;
            }

            affectedRecorditem.Save();
            updateAdditionalImageInList(file);
        }

        Utils.OpenImages([file], file.Filename, false, null, onMarksSaved);
    }

    function createNewComment(text: string, $li): Deferred {
        const now = new Date();
        const comment = new Model.Comment(
            uuid(),
            now,
            now,
            Session.User.OID,
            Session.User.OID,
            text,
            affectedRecorditem.OID,
            Enums.CommentType.RecorditemComment);

        // set issue as helper information
        if (Session.IsSmartDeviceApplication) {
            if (affectedRecorditem.IssueID) {
                comment.IssueID = affectedRecorditem.IssueID;
            }
            if (affectedRecorditem.IssueOID) {
                comment.IssueOID = affectedRecorditem.IssueOID;
            }
        }

        return comment.Save()
            .then((comment: Model.Comment) => { onAfterCommentSaved(comment, $li); })
            .then(() => comment)
            .fail(function(_response, _state, _error) {
                throw new Model.Errors.HttpError(_error, _response);
            });
    }

    function createNewCorrectiveActionFromPhoto(evt: Event, photo): void {
        if (!Utils.CanUserCreateIssueType(Enums.IssueType.Task, Session.CurrentLocation)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                {
                    Close: true
                }, null, 1054);
            return;
        }

        if (evt) {
            evt.stopPropagation();
        }

        const action = {
            Type: Enums.CheckpointWorkflowType.CorrectiveAction,
            TemporaryFiles: null,
            InheritResponsibilities: null
        };

        let parentIssue: Model.Issues.Issue;

        if (photo) {
            const photoCopy = $.extend(true, {}, photo);
            photoCopy.UploadDisabled = true;

            action.TemporaryFiles = [photoCopy];
        }

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            parentIssue = IssueView.GetCurrentIssue();

            if (parentIssue.AssignedFormRevisionOID) {
                const form = ParameterList.GetElementRevisionByRevisionOID(parentIssue.AssignedFormRevisionOID);
                action.InheritResponsibilities = form && form.AdditionalSettings && form.AdditionalSettings.BequeathResponsibilities;
            }
        }

        let locationIdentifier = Session.CurrentLocation.OID;

        if (affectedIssue && affectedIssue.Type === Enums.IssueType.Form) {
            locationIdentifier = affectedIssue.AssignedElementOID;
        }

        if (!Utils.IsUserAbleToSetInitialState(locationIdentifier, Session.Client.Settings.TicketOpened)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.DefaultState.MessageBody'),
                {
                    Close: true
                }, null, 1054);

            return;
        }

        Utils.IssueViewer.CreateCorrectiveAction(affectedRecorditem,
            affectedElement,
            parentIssue,
            action,
            false,
            (issue: Model.Issues.Issue) => { onIssueSaved(issue, affectedIssue); },
            false);
    }

    function determineActions(affectedElement: Model.Elements.Element): Array<any> {
        const actions = [];
        if ((affectedElement.Actions || []).length) {

            affectedElement.Actions.sort(function(a, b) {
                return (a.Order || 0) - (b.Order || 0);
            });

            for (let aCnt = 0, aLen = affectedElement.Actions.length; aCnt < aLen; aCnt++) {
                let action = affectedElement.Actions[aCnt];

                if (action.CategoryOID !== affectedRecorditem.CategoryOID) {
                    continue;
                }

                if (action.Type === Enums.CheckpointWorkflowType.Notification) {
                    continue;
                }

                if (action.Type === Enums.CheckpointWorkflowType.Form &&
                    !action.FormOID) {
                    continue;
                }

                if (action.TriggerAutomatically) {
                    continue;
                }

                // Der Foto-Workflow braucht nur einmal angezeigt werden
                if (action.Type === Enums.CheckpointWorkflowType.Photo &&
                    Utils.Where(actions, 'Type', '===', Enums.CheckpointWorkflowType.Photo) != null) {
                    continue;
                }

                actions.push($.extend(true, {}, action));
            }
        }

        return actions;
    }

    function takeAdditionalImage(selectedCorrectiveAction): void {
        Utils.RequestCamera().then(function(filePath) {
            onAfterGotImagePath(filePath, selectedCorrectiveAction);
        }, Utils.Spinner.Hide);
    }

    function addAdditionalImageToList(file: Model.Files.File): void {
        $win.find('.image-list')
            .append(Templates.CorrectiveActions.AdditionalImage({
                File: file
            }));

        $win.find('.image-list li[data-filename="{0}"] img'.format(file.Filename))
            .on('load', function() { Utils.RepositionModalWindow($win); });
    }

    function updateAdditionalImageInList(file: Model.IFileProperties): void {
        const $listItem = $win.find('.image-list li[data-filename="{0}"]'.format(file.Filename));

        if ($listItem.length) {
            $listItem.replaceWith(Templates.CorrectiveActions.AdditionalImage({
                File: file
            }));
        }
    }

    function getAction(oid: string): any {
        if (!(actions || []).length) {
            return null;
        }

        for (let aCnt = 0, aLen = actions.length; aCnt < aLen; aCnt++) {
            if (actions[aCnt].OID === oid) {
                return actions[aCnt];
            }
        }
    }

    function getWorkflowInfoText(action): string {
        if (!action || action.Type === Enums.CheckpointWorkflowType.Comment) {
            return;
        }

        let workflowText: string;

        if (!action.IsIssue) {
            if (action.ActionState === Enums.ActionState.Done) {
                workflowText = i18next.t('CorrectiveActions.ActionState.Done');
            } else if (Utils.InArray([Enums.ActionState.Postpone,
            Enums.ActionState.CreateIssue,
            Enums.ActionState.StartAcquisition], action.ActionState)) {
                workflowText = i18next.t('CorrectiveActions.ActionState.Created');
            } else if (action.ActionState === Enums.ActionState.Unapplicable) {
                workflowText = i18next.t('CorrectiveActions.ActionState.Unapplicable');
            }

            if (action.Type === Enums.CheckpointWorkflowType.CorrectiveAction && !!action.Text) {
                workflowText += ': ' + Utils.UnescapeHTMLEntities(action.Text);
            } else if (action.Type === Enums.CheckpointWorkflowType.Form) {
                if (action.Issue) {
                    if (!Session.IsSmartDeviceApplication) {
                        workflowText += `: ${i18next.t('Misc.IssueType.Abbreviation.Task')}${action.Issue.ID}.1`;

                        if (!!action.Issue.Title) {
                            workflowText += ' - ' + Utils.UnescapeHTMLEntities(action.Issue.Title);
                        }
                    } else if (!!action.Issue.Title) {
                        workflowText += ': ' + Utils.UnescapeHTMLEntities(action.Issue.Title);
                    }
                } else {
                    const form = DAL.Elements.GetByOID(action.FormOID)
                    workflowText += ': ' + (form ? form.Title : i18next.t('CorrectiveActions.UnknownForm'));
                }
            } else if (action.Type === Enums.CheckpointWorkflowType.Photo) {
                workflowText = i18next.t('CorrectiveActions.PhotoWorkflowText');
            }
        } else {
            workflowText = i18next.t('CorrectiveActions.TaskCreated', { IssueTitle: action.Issue.Title || i18next.t('Misc.Untitled') });
        }

        return workflowText;
    }

    function createInfoText(): void {
        const workflowInformation = [];

        if (correctiveActions.length) {
            for (let aCnt = 0, aLen = correctiveActions.length; aCnt < aLen; aCnt++) {
                const action = correctiveActions[aCnt];

                workflowInformation.push(getWorkflowInfoText(action));
            }
        }

        affectedRecorditem.WorkflowInformation = workflowInformation.length ? workflowInformation.join('\n\n') : null;

        if (!issuesAreTemporary) {
            affectedRecorditem.IsUpdate = true;
            affectedRecorditem.Save();
        }
    }

    function resetActionState(actionOID: string): void {
        const actionIdx = Utils.Where(correctiveActions, 'OID', '===', actionOID, true);

        $win.find('li[data-oid="{0}"]'.format(actionOID))
            .removeAttr('data-issueoid').removeAttr('data-state');

        correctiveActions.splice(actionIdx, 1);
        createInfoText();
    }

    function bindEvents(): void {
        $win.on('click', 'button[data-action]', onActionButtonClick);
        $win.on('click', 'li[data-type][data-oid][data-state]', onTriggeredActionClick);
        $win.on('click', 'li[data-issueoid] .btn-danger', onCorrectionIssueButtonClick);
        $win.on('click', 'li:not([data-type])[data-issueoid]', onCorrectiveActionIssueClick);
        $win.on('click', '.btn-close', onBtnCloseClick);
        $win.find('.image-list').on('click', 'li', onAdditionalImageClick);
        $win.on('hidden.bs.modal', destroy);

        if (!Session.IsSmartDeviceApplication) {
            $win.find('input[type="file"]').on('change', onFileInput);
        }

        if (onSave instanceof Function) {
            $win.on('click', '.btn-apply', onBtnApplyClick);
        }
    }

    export function SavePredefinedActions(recordItem?: Model.Recorditem): Deferred {
        Utils.Spinner.Show();

        recordItem = recordItem || affectedRecorditem;

        recordItem.CorrectiveActions = $.extend(true, [], recordItem.CorrectiveActions || []);

        // Fixing double entries
        const tmpCorrectiveActions: Dictionary<Model.Issues.Issue | Model.Issues.RawIssue> = {};
        for (let i = 0, len = recordItem.CorrectiveActions.length; i < len; i++) {
            const action = recordItem.CorrectiveActions[i];
            if (action) {
                tmpCorrectiveActions[action.OID] = action;
            }
        }

        return (function saveByIndex(idx: number): Deferred {
            const correctiveAction = correctiveActions[idx];
            const tmpIssue = new Model.TemporaryIssue(correctiveAction.Issue, correctiveAction.AdditionalFiles, affectedRecorditem.OID);

            return tmpIssue.Save()
                .then(function(savedIssue: Model.Issues.Issue) {
                    correctiveAction.Issue = savedIssue;

                    // temporary fix / #hack
                    tmpCorrectiveActions[savedIssue.OID] = savedIssue;
                    affectedRecorditem.CorrectiveActions = $.map(tmpCorrectiveActions, (issue: Model.Issues.IssueType) => issue);

                    delete correctiveAction.IsTemporary;

                    if ((++idx) < correctiveActions.length) {
                        return saveByIndex(idx);
                    }
                });
        })(0)
            .then(() => Utils.RemoveTemporaryImages())
            .always(() => Utils.Spinner.Hide());
    }

    function render(showCorrectiveIssuesOnly?: boolean): boolean {
        let windowTitle: string;

        const automatedWorkflowIssues = (automaticWorkflows || []).filter(e => e != null && e.Issue != null);

        if (!showCorrectiveIssuesOnly) {
            actions = determineActions(affectedElement) || [];

            if (!actions.length && !automatedWorkflowIssues.length) {
                return false;
            }

            let category = DAL.Properties.GetByOID(affectedRecorditem.CategoryOID);

            if (!category) {
                category = <any>{
                    Title: i18next.t('Misc.Unknown')
                };
            }

            windowTitle = i18next.t('CorrectiveActions.WindowTitle', {
                CategoryTitle: category.Title,
                ParameterTitle: affectedElement.Title
            });
        } else {
            windowTitle = i18next.t('CorrectiveActions.WindowTitleWithoutCategory', {
                ParameterTitle: affectedElement.Title
            });
        }

        Utils.Message.Hide();

        const locationIdentifier: string = affectedIssue ? affectedIssue.AssignedElementOID : null;

        if (Session.LastKnownAPIVersion >= 7) {
            for (let i = 0; i < actions.length; ++i) {
                if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyCommentsOnCheckpoint, true, locationIdentifier) &&
                    actions[i].Type === Enums.CheckpointWorkflowType.Comment) {
                    actions.splice(i, 1);
                    i--;
                    continue;
                }

                if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue, true, locationIdentifier) &&
                    (actions[i].Type === Enums.CheckpointWorkflowType.CorrectiveAction ||
                        actions[i].Type === Enums.CheckpointWorkflowType.Form)) {
                    actions.splice(i, 1);
                    i--;
                    continue;
                }

                if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint, true, locationIdentifier) &&
                    actions[i].Type === Enums.CheckpointWorkflowType.Photo) {
                    actions.splice(i, 1);
                    i--;
                }
            }

            if (actions.length === 0 && !Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue, true, locationIdentifier)) {
                return false;
            }
        }

        const windowSettings: any = {
            Title: windowTitle,
            AutomaticWorkflowIssues: automatedWorkflowIssues,
            Actions: actions,
            ExistingCorrectiveActionIssues: correctiveActions,
            ShowSaveAndAbortButton: issuesAreTemporary
        };

        $win = $(Templates.CorrectiveActions.Window(windowSettings));

        $overlay = Utils.Overlay.Generate('olActions', 1052, destroy);

        $('body').append($win);

        $win.find('.modal-content').css('max-height', $(window).height() - 60);

        Utils.RepositionModalWindow($win);

        $win.modal({
            show: true,
            keyboard: false,
            backdrop: false
        });

        bindEvents();

        return true;
    }

    export function SortCorrectiveActionIssuesByID(a: Action, b: Action): number {
        if ((a.Issue.ID || 0) === (b.Issue.ID || 0)) {
            const dateA = new Date(<string>a.Issue.CreationTimestamp)
            const dateB = new Date(<string>b.Issue.CreationTimestamp)

            // neue Vorgänge weiter unten sortieren
            return dateA.getTime() - dateB.getTime();
        }

        if ((a.Issue.ID || 0) === 0) {
            return 1;
        }

        if ((b.Issue.ID || 0) === 0) {
            return -1;
        }

        // neue Vorgänge weiter unten sortieren
        return a.Issue.ID - b.Issue.ID;
    }

    function getParameter(recorditem: Model.Recorditem): Model.Elements.Element {
        if (Utils.InArray([Enums.View.Form, Enums.View.Inspection], View.CurrentView)) {
            const resubTree = ParameterList.GetResubmissionTree();

            if (!resubTree || !(resubTree.Parametergroups || []).length) {
                return null;
            }

            for (let gCnt = 0, gLen = resubTree.Parametergroups.length; gCnt < gLen; gCnt++) {
                const group = resubTree.Parametergroups[gCnt];

                if ((group.Type === Enums.ElementType.FormRow && group.Row === recorditem.Row ||
                    group.Type !== Enums.ElementType.FormRow) &&
                    (group.Parameters || []).length) {
                    for (let pCnt = 0, pLen = group.Parameters.length; pCnt < pLen; pCnt++) {
                        const param = group.Parameters[pCnt];
                        param.Row = group.Row;

                        if (param.RevisionOID === recorditem.ElementRevisionOID ||
                            param.OID === recorditem.ElementOID) {
                            return param;
                        }
                    }
                }
            }
        } else if (View.CurrentView === Enums.View.Scheduling) {
            return ParameterList.GetElementRevisionByRevisionOID(recorditem.ElementRevisionOID);
        } else {
            return DAL.Elements.GetByOID(recorditem.ElementOID);
        }

        return null;
    }

    export function CreateWorkflowInformation(correctiveActions: Action[], existingCorrectiveActions: Action[]): string | null {
        const workflowInformation = [];
        const allActionsIndex = {};
        const allActions = [];

        // bestehende Korrekturmaßnahmen
        if ((existingCorrectiveActions || []).length) {
            existingCorrectiveActions.forEach((action: Utils.ActionWindow.Action) => {
                if (allActionsIndex[action.Issue.OID]) {
                    return;
                }

                allActions.push(action);
                allActionsIndex[action.Issue.OID] = true;
            });
        }

        // neue Korrekturmaßnahmen
        if ((correctiveActions || []).length) {
            correctiveActions.forEach((action) => {
                if (allActionsIndex[action.Issue.OID]) {
                    return;
                }

                allActions.push(action);
                allActionsIndex[action.Issue.OID] = true;
            });
        }

        // sortieren nach Vorgang ID Absteigend (neue weiter oben)
        allActions.sort(SortCorrectiveActionIssuesByID);

        // als Workflow Text aufbereiten
        for (let i = 0; i < allActions.length; i++) {
            const action = allActions[i];
            workflowInformation.push(getWorkflowInfoText(action));
        }

        return workflowInformation.length ? workflowInformation.join('\n\n') : null;
    }

    export function AddActionWindowToQueue(actionWindowSettings: Settings): void {
        if (Utils.InArray(actionWindowQueue, actionWindowSettings)) {
            return;
        }

        actionWindowQueue = actionWindowQueue || [];
        actionWindowQueue.push(actionWindowSettings);
    }

    export function RemoveActionWindowFromQueue(actionWindowSettings: Settings): void {
        if ((actionWindowQueue || []).length === 0) {
            return;
        }

        const index = Utils.GetIndex(actionWindowQueue, actionWindowSettings);

        if (index > -1) {
            actionWindowQueue.splice(index, 1);
        }
    }

    function getQueuePosition(actionWindowSettings: Settings): number {
        return Utils.GetIndex(actionWindowQueue, actionWindowSettings);
    }

    export function IsQueueEmpty(): boolean {
        return (actionWindowQueue || []).length < 1;
    }

    export function ClearActionWindowQueue(): void {
        actionWindowQueue = [];
    }

    export function CloseActionWindows() {
        destroy();
    }

    export function ShowNextActionWindow(callback: Function): Deferred {
        if (callback instanceof Function && (actionWindowQueue || []).length) {
            return Show(actionWindowQueue[0])
                .then(callback);
        }

        return $.Deferred.resolve(false).promise();
    }

    export function Show(actionWindowSettings: Settings): Deferred {
        if (!actionWindowSettings || !actionWindowSettings.Recorditem || !actionWindowSettings.ActionWindowCallback) {
            return $.Deferred().resolve(false).promise();
        }

        AddActionWindowToQueue(actionWindowSettings);

        if (getQueuePosition(actionWindowSettings) !== 0 || IsVisible()) {
            return $.Deferred().resolve(false).promise();
        }

        RemoveActionWindowFromQueue(actionWindowSettings);

        automaticWorkflows = actionWindowSettings.AutomaticWorkflows;
        affectedRecorditem = actionWindowSettings.Recorditem;
        affectedIssue = actionWindowSettings.Issue;
        correctiveActions = actionWindowSettings.PredefinedCorrectiveActions || [];
        affectedElement = affectedRecorditem ? getParameter(affectedRecorditem) : null;

        const leaveActionWindow = (!actionWindowSettings.ShowCorrectiveIssuesOnly && !affectedRecorditem.CategoryOID) ||
            !affectedElement ||
            !(affectedElement.Actions || []).length;

        if (leaveActionWindow) {
            if ((correctiveActions || []).length) {
                return SavePredefinedActions()
                    .then(function() {
                        affectedRecorditem = null;
                        correctiveActions = null;
                    });
            }

            return $.Deferred().resolve(false).promise();
        }

        if (!(affectedRecorditem instanceof Model.Recorditem)) {
            affectedRecorditem = new Model.Recorditem(affectedRecorditem);
        }

        affectedRecorditem.SetOnAfterSavedHandler(onRecorditemUpdated);

        issuesAreTemporary = actionWindowSettings.IssuesAreTemporary;

        onSaveSingleIssue = actionWindowSettings.OnAfterCorrectiveIssueSaved;
        onSave = actionWindowSettings.OnAfterCorrectiveIssuesSaved;
        onAfterClosed = actionWindowSettings.OnAfterClosed;

        const locationIdentifier = affectedIssue ? affectedIssue.AssignedElementOID : null;

        if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyOnCheckpoints, true, locationIdentifier)) {
            return $.Deferred().resolve().promise();
        }

        closeDeferred = $.Deferred();

        function renderWindow(showCorrectiveIssuesOnly?: boolean) {
            const windowIsShown = render(showCorrectiveIssuesOnly);

            actionWindowSettings.ActionWindowCallback(windowIsShown)
                .then((isShown: boolean) => {
                    if (!isShown && closeDeferred) {
                        closeDeferred.resolve();
                    }
                });
        }

        if (!actionWindowSettings.ShowCorrectiveIssuesOnly) {
            if (correctiveActions.length) {
                SavePredefinedActions()
                    .then(renderWindow);
            } else {
                renderWindow();
            }
        } else {
            renderWindow(actionWindowSettings.ShowCorrectiveIssuesOnly);
        }

        return closeDeferred.promise();
    }

    export function IsVisible(): boolean {
        return $win && $win.css('display') !== 'none';
    }
}
