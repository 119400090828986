//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./model.errors.ts"  />
/// <reference path="../app/app.session.ts"  />
/// <reference path="../utils/base64binary.ts"  />
//imports-end

module Model {
    export interface IFileProperties {
        Filename: string,
        Description?: string,
        Title?: string,
        OID: string,
        MimeType: Enums.MimeType,
        Position: number,
        FileURI?: string,
        IsImage?: boolean,
        IsAudio?: boolean,
        IsVideo?: boolean,
        Marks?: string,
        Metrics?: string,
        Content?: string,    // internal use only
        IsTemporary?: boolean,    // internal use only
        ModificationType?: Enums.AdditionalImageModificationType,    // internal use only
        OriginalFilename?: string,  // internal use only
        IsCopy?: boolean    // internal use only
    }

    export interface IFilesRecorditemValue {
        Filename: string;
        Title: string;
        Description?: string;
        MimeType: string;
    }

    // TODO Es sollten alle Stellen geprüft werden, wo Model.Recorditem verwendet wird. An einigen Stellen wird Model.Recorditem verwendet, obwohl es nur ein Objekt und keine Instanz ist.
    // Dadurch kann es vorkommen das Methoden aufgerufen werden die nicht zur verfügung stehen und eine Fehlermeldung verursachen
    // Da der Aufwand zur groß ist um dies im Kunden Ticket mitzumachen wurde dies als separates Ticket angelegt
    export interface RawRecorditem {
        Element: Model.Elements.Element;
        ElementRevisionOID: string;
        Row?: number;
        Creator: Model.Users.User;
        Editor: Model.Users.User;
        ElementOID: string;
        CreatorOID: string;
        EditorOID: string;
        Comments: Array<any>;
        OnAfterSaved: Function;
        OnAfterError: (recorditem, response, state, error) => Deferred;
        CorrectiveActions;
        ExistingCorrectiveActionIssues;
        GetSignature: (c: Function) => void;
        GetImageCopy: () => Deferred;
        GetAdditionalImageCopy: (path: string) => Deferred;
        NewFiles: Array<any>;
        ValueImage;
        NewComments: Array<Model.Comment>;
        Value: any;
        CategoryOID: string;
        AdditionalFiles: Array<IFileProperties>;
        OID: string;
        IssueOID: string;
        ModificationTimestamp;
        Filename: string;
        IsUpdate: boolean;
        ID: number;
        NewCorrectiveActions;
        IsHistorical: boolean;
        IsUnsynced: boolean;
        Type: Enums.RecorditemType;

        WorkflowInformation: string;
        IssueID: number;
        ResubmissionitemOID: string;
        CreationTimestamp;
        DeadlineTimestamp;
        Revision: number;
        PrecedingOID: string;
        IsDefault?: boolean;
        Images?;
        ElementType: Enums.ElementType;
        FollowerOID: string;

        //AppOnly
        IsDummy?: boolean;
        IsDeleted?: boolean;
    }

    export type GetSignatureFunc = (r: string[] | Blob) => void;

    export class Recorditem implements RawRecorditem {
        Element: Model.Elements.Element;
        ElementRevisionOID: string;
        Row?: number;
        Creator: Model.Users.User;
        Editor: Model.Users.User;
        ElementOID: string;
        CreatorOID: string;
        EditorOID: string;
        Comments: Array<Model.Comment | any>;
        OnAfterSaved: Function;
        OnAfterError: (recorditem: Model.Recorditem, response, state, error) => Deferred;
        CorrectiveActions: (Model.Issues.Issue | Model.Issues.RawIssue)[];
        ExistingCorrectiveActionIssues: (Model.Issues.Issue | Model.Issues.RawIssue)[];
        GetSignature: (c: GetSignatureFunc) => void;
        GetImageCopy: () => Deferred;
        GetAdditionalImageCopy: (path: string) => Deferred;
        NewFiles: Array<Model.IFileProperties>;
        ValueImage;
        NewComments: Array<Model.Comment>;
        Value: any;
        AdditionalValueInfo?: { Marks?: string };
        CategoryOID: string;
        AdditionalFiles: Array<IFileProperties>;
        OID: string;
        IssueOID: string;
        ModificationTimestamp;
        Filename: string;
        IsUpdate: boolean;
        IsImageMarksUpdate: boolean;
        ID: number;
        NewCorrectiveActions: any[];
        IsHistorical: boolean;
        IsUnsynced: boolean;
        Type: Enums.RecorditemType;
        HasCorrectiveActions: boolean;

        WorkflowInformation: string;
        IssueID: number;
        ResubmissionitemOID: string;
        CreationTimestamp;
        DeadlineTimestamp;
        Revision: number;
        PrecedingOID: string;
        IsDefault?: boolean;
        Images?: Model.IFileProperties[];
        ElementType: Enums.ElementType;
        FollowerOID: string;
        ChangeID?: string;

        //AppOnly
        IsDummy?: boolean;
        IsDeleted?: boolean;

        constructor(recorditem?: any) {
            if (!arguments.length) {
                return;
            }

            if (!recorditem) {
                throw new Model.Errors.ArgumentError('No recorditem given');
            }

            this.prepareRecorditem(recorditem);
        }

        private prepareRecorditem(recorditem: any): void {
            for (let attr in recorditem) {
                const value = recorditem[attr];

                if (!recorditem.hasOwnProperty(attr)) {
                    continue;
                }

                if (value !== null) {
                    this[attr] = value;
                }
            }

            this.Element = Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection, Enums.View.Scheduling], View.CurrentView) ?
                ParameterList.GetElementRevisionByRevisionOID(this.ElementRevisionOID, this.Row) :
                DAL.Elements.GetByOID(this.ElementOID);

            if (!this.Element) {
                this.Element = DAL.Elements.GetByRevisionOID(this.ElementRevisionOID) || DAL.Elements.GetByOID(this.ElementOID);
            }

            if (!this.Element) {
                this.Element = ParameterList.GetElementRevisionByOID(this.ElementOID, this.Row);
            }

            this.Creator = DAL.Users.GetByOID(this.CreatorOID);
            this.Editor = DAL.Users.GetByOID(this.EditorOID);

            const commentsLength = (this.Comments || []).length;
            if (commentsLength) {
                for (let cCnt = 0; cCnt < commentsLength; cCnt++) {
                    const comment = this.Comments[cCnt];

                    if (comment instanceof Model.Comment) {
                        continue;
                    }

                    this.Comments[cCnt] = new Model.Comment(comment);
                }

                this.Comments.sort(this.sortCommentsByModificationTimestamp);
            }

            const correctiveActionsLength = (this.CorrectiveActions || []).length;
            for (let cCnt = 0; cCnt < correctiveActionsLength; cCnt++) {
                if (this.CorrectiveActions[cCnt] instanceof Model.Issues.Issue) {
                    continue;
                }

                this.CorrectiveActions[cCnt] = DAL.Issues.PrepareIssue(this.CorrectiveActions[cCnt]);
            }
        }

        private sortCommentsByModificationTimestamp(a: Model.Comment, b: Model.Comment): number {
            const ta = (<Date>a.ModificationTimestamp).getTime();
            const tb = (<Date>b.ModificationTimestamp).getTime();

            return ta - tb;
        }

        private saveSyncEntity(): Deferred {
            let dependingOnOID = this.IssueOID;
            const currentIssue = IssueView.GetCurrentIssue();

            if (!dependingOnOID && this.Row > 0 && !this.ID) {
                // Abhängigkeit zu evtl. neu angelegten Teilproben generieren
                if (currentIssue && currentIssue.ResubmissionitemCollection) {
                    const resubItem = currentIssue.ResubmissionitemCollection.Dictionary[this.ResubmissionitemOID]
                    if (resubItem && resubItem.Parent) {
                        const resubGroupOID = resubItem.Parent.ElementOID;
                        dependingOnOID = `${currentIssue.OID}-${resubGroupOID}-${this.Row}_SUBSAMPLE`;
                    }
                }
            }

            // Abhängigkeit zu LockState aufbauen
            if (!currentIssue || !this.Element) {
                const recorditemSyncEntityDescription = new Model.Synchronisation.RecordItemEntityDescription(
                    this, dependingOnOID
                );

                return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, recorditemSyncEntityDescription);
            }

            return IssueView.GetLastLockStateSyncEntity(this.Element.Parent, currentIssue.OID, false)
                .then((entityOID: string) => {
                    const recorditemSyncEntityDescription = new Model.Synchronisation.RecordItemEntityDescription(
                        this, entityOID || dependingOnOID
                    );

                    return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, recorditemSyncEntityDescription)
                });
        }

        private saveScancodeInfo(): Deferred {
            if (!this.Value ||
                !this.Element ||
                this.Element.Type !== Enums.ElementType.Scancode ||
                !this.Element.AdditionalSettings ||
                !this.Element.AdditionalSettings.CheckForUniqueness) {
                return $.Deferred().resolve().promise();
            }

            const scanCodeInfo = Model.Recorditem.CreateScanCodeInfo(this);
            let deferred = $.Deferred().resolve();

            if (Session.IsSmartDeviceApplication) {
                deferred = deferred.then(() => window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.ScanCodes, scanCodeInfo))
            }

            return deferred
                .then(() => DAL.ScancodeInfos.Store([scanCodeInfo]));
        }

        private saveFileSyncEntity(): Deferred {
            const recorditemFileSyncEntityDescription = new Model.Synchronisation.RecordItemFileEntityDescription(
                this.Value, Enums.SyncEntityType.RecorditemValueFile,
                this, null, this.ModificationTimestamp);

            return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, recorditemFileSyncEntityDescription);
        }

        public OnAfterSavedToServer(id: number): Deferred {
            this.ID = id;

            if ((this.ExistingCorrectiveActionIssues || []).length) {
                this.CorrectiveActions = $.extend(true, [], this.ExistingCorrectiveActionIssues);
            }

            if (!(!!this.Value && this.Element &&
                Utils.InArray([Enums.ElementType.Photo, Enums.ElementType.Signature], this.Element.Type) &&
                !this.IsUpdate)) {
                return $.Deferred().resolve();
            }

            if (Utils.IsSet(this.GetImageCopy) && this.GetImageCopy instanceof Function) {
                return this.GetImageCopy()
                    .then((image) => {
                        if (!image) {
                            return $.Deferred().resolve();
                        }

                        return this.uploadFile(image);
                    });
            }

            if (this.Element.Type === Enums.ElementType.Signature) {
                const deferred = $.Deferred();

                this.GetSignature((signature) => {
                    this.uploadSignature(signature)
                        .then(deferred.resolve, deferred.reject);
                });

                return deferred.promise();
            } else {
                return this.uploadValueFile();
            }
        }

        private onAfterFileUploaded(uploadedFileInformation, marks, position: number): any {
            if (!uploadedFileInformation) {
                return null;
            }

            const file = JSON.parse(uploadedFileInformation);

            file.Marks = marks;
            file.Position = position;
            file.IsImage = Utils.IsImage(file.MimeType);
            file.IsAudio = Utils.IsAudio(file.MimeType);

            if (file.MimeType.contains('image/') && file.Metadata) {
                file.Metrics = {
                    s: {
                        width: 0,
                        height: 0
                    },
                    m: {
                        width: 0,
                        height: 0
                    },
                    l: {
                        width: 0,
                        height: 0
                    },
                    o: {
                        width: 0,
                        height: 0
                    }
                };

                let tmp;
                if ((tmp = file.Metadata.SizeS.split('x')).length === 2) {
                    file.Metrics.s.width = parseInt(tmp[0], 10) || 0;
                    file.Metrics.s.height = parseInt(tmp[1], 10) || 0;
                }
                if ((tmp = file.Metadata.SizeM.split('x')).length === 2) {
                    file.Metrics.m.width = parseInt(tmp[0], 10) || 0;
                    file.Metrics.m.height = parseInt(tmp[1], 10) || 0;
                }
                if ((tmp = file.Metadata.SizeL.split('x')).length === 2) {
                    file.Metrics.l.width = parseInt(tmp[0], 10) || 0;
                    file.Metrics.l.height = parseInt(tmp[1], 10) || 0;
                }
                if ((tmp = file.Metadata.SizeOriginal.split('x')).length === 2) {
                    file.Metrics.o.width = parseInt(tmp[0], 10) || 0;
                    file.Metrics.o.height = parseInt(tmp[1], 10) || 0;
                }
            }

            return file;
        }

        private uploadValueFile(): Deferred {
            return this.ValueImage
                ? this.uploadFile(this.ValueImage)
                : $.Deferred().resolve();
        }

        private uploadSignature(signature): Deferred {
            if (!signature) {
                return this.uploadValueFile();
            }

            return this.uploadFile(signature);
        }

        private uploadFile(file: any): Deferred {
            return Utils.UploadFile(`${Session.BaseURI}records/files/${this.Value}${Utils.GetAuthQueryParameter('?')}`, file);
        }

        private uploadAdditionalFiles(): Deferred {
            const instance = this;
            const deferred = $.Deferred();
            const result: Model.IFileProperties[] = [];
            const newFilesLength = (instance.NewFiles || []).length;

            if (!newFilesLength) {
                return deferred.resolve(result).promise();
            }

            let uploadDeferreds: Deferred[] = [];

            for (const file of instance.NewFiles) {
                if (file.ModificationType !== Enums.AdditionalImageModificationType.CREATED) {
                    continue;
                }

                const getFileDeferred = $.Deferred();

                if (file.IsCopy && instance.GetAdditionalImageCopy) {
                    instance.GetAdditionalImageCopy(file.OriginalFilename)
                        .then(getFileDeferred.resolve, getFileDeferred.reject);
                } else {
                    getFileDeferred.resolve(file);
                }

                const uploadDeferred = getFileDeferred.then((f) => {
                    return Utils.UploadFile(Model.Recorditem.getAdditionalFileUrl(instance, file), f)
                        .then(function(uploadedFileInformation, marks: string, position: number) {
                            delete file.IsTemporary;

                            result.push(instance.onAfterFileUploaded(uploadedFileInformation, marks || file.Marks, position));

                            instance.AdditionalFiles = instance.coalesceAdditionalFiles(result);
                        }, deferred.reject);
                });

                uploadDeferreds.push(uploadDeferred);
            }

            $.when.apply($, uploadDeferreds)
                .then(deferred.resolve, deferred.resolve);

            return deferred.promise();
        }

        private static getAdditionalFileUrl(instance: Model.Recorditem, additionalImage) {
            let uri = `${Session.BaseURI}recorditems/${instance.OID}/files?filename=${additionalImage.newFilename || additionalImage.Filename}`;

            if (Utils.IsSet(additionalImage.Position)) {
                uri += `&position=${additionalImage.Position}`;
            }

            if (Utils.IsSet(additionalImage.Title)) {
                uri += `&title=${additionalImage.Title}`;
            }

            if (Utils.IsSet(additionalImage.Description)) {
                uri += `&description=${additionalImage.Description}`;
            }

            uri += Utils.GetAuthQueryParameter('?')

            return uri;
        }

        private moveAdditionalImage(fileDescription): Deferred {
            if (fileDescription.ModificationType !== Enums.AdditionalImageModificationType.CREATED) {
                return $.Deferred().resolve().promise();
            }

            const recorditem = this;
            // Neue Dateien in Resources Ordner verschieben
            return Utils.MoveFileToResources(fileDescription.FileURI, fileDescription.Filename)
                .then(() => {
                    const syncEntityDescription = new Model.Synchronisation.RecordItemFileEntityDescription(
                        fileDescription.Filename, Enums.SyncEntityType.RecorditemAdditionalFile,
                        recorditem, fileDescription.OID, fileDescription.ModificationTimestamp
                    );

                    return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, syncEntityDescription);
                })
        }

        private moveAdditionalImages(): Deferred {
            let deferred = $.Deferred().resolve();

            if (!(this.NewFiles || []).length) {
                return deferred.promise();
            }

            for (let image of this.NewFiles) {
                deferred = deferred
                    .then(() => this.moveAdditionalImage(image));
            }

            return deferred
                .then(() => {
                    if ((this.ExistingCorrectiveActionIssues || []).length) {
                        this.CorrectiveActions = $.extend(true, [], this.ExistingCorrectiveActionIssues);
                    }
                });
        }

        private savePhotoRecorditem(): Deferred {
            const fileExtension = Utils.GetFileExtension(this.Value, '.jpg');
            const newFilename = uuid() + fileExtension;

            return Utils.MoveFileToResources(this.Value, newFilename)
                .then((newFilename: string) => {
                    this.Value = newFilename;
                })
        }

        private saveSignatureRecorditem(): Deferred {
            const instance = this;
            const deferred = $.Deferred();

            // Beim Speichern eines Workflows wird die Signatur nicht benötigt und kann null sein
            if (!this.GetSignature) {
                return deferred.resolve();
            }

            this.GetSignature(function(signature: Blob) {
                if (!signature) {
                    deferred.reject();
                    return;
                }

                Session.ResourcesFolder.getFile(
                    instance.Value,
                    { create: true },
                    (file) => {
                        file.createWriter(function(writer) {
                            writer.onwrite = function() {
                                deferred.resolve();
                            };

                            writer.onerror = deferred.reject;

                            const utf8_string = Base64Binary.decodeArrayBuffer(signature[1]);
                            writer.write(utf8_string);
                        }, deferred.reject);

                        deferred.resolve();
                    }, deferred.reject);
            });

            return deferred.promise();
        }

        private savePhotoCopyToDevice(): Deferred {
            const deferred = $.Deferred();

            this.GetImageCopy()
                .then((data) => {
                    if (!Utils.IsSet(data)) {
                        deferred.reject();
                        return;
                    }

                    Utils.WriteBlobToFile(this.Value, data)
                        .then(deferred.resolve, deferred.reject);
                });

            return deferred.promise();
        }

        private saveComments(): Deferred {
            const unsavedComments = this.NewComments || [];
            const deferred = $.Deferred();
            const instance = this;

            if (unsavedComments.length <= 0) {
                return deferred.resolve().promise();
            }

            (function saveComment(idx: number) {
                const comment = unsavedComments[idx];

                function loop() {
                    const commentIdx = Utils.GetIndex(instance.Comments, comment.OID, 'OID');

                    if (commentIdx > -1) {
                        instance.Comments.splice(commentIdx, 1);
                        instance.Comments.push(comment.GetRawEntity());
                    }

                    if (idx === unsavedComments.length - 1) {
                        deferred.resolve();
                    } else {
                        saveComment(++idx);
                    }
                }

                comment
                    .Save()
                    .always(loop);
            })(0);

            return deferred.promise();
        }

        private saveCorrectiveActions(): Deferred {
            if (!this.NewCorrectiveActions || !this.NewCorrectiveActions.length) {
                return $.Deferred().resolve();
            }

            return (function saveByIndex(idx: number, recordItem: Model.Recorditem): Deferred {
                if (idx >= recordItem.NewCorrectiveActions.length) {
                    return $.Deferred().resolve();
                }

                const correctiveAction = recordItem.NewCorrectiveActions[idx];

                if (!correctiveAction) {
                    return saveByIndex(idx + 1, recordItem);
                }

                const tmpIssue = new Model.TemporaryIssue(correctiveAction.Issue, correctiveAction.AdditionalFiles, recordItem.OID);

                return tmpIssue.Save()
                    .then(function() {
                        delete correctiveAction.IsTemporary;

                        if ((++idx) < recordItem.NewCorrectiveActions.length) {
                            return saveByIndex(idx, recordItem);
                        }
                    });
            })(0, this)
                .then(() => {
                    // NewCorrectiveActions zurücksetzen nachdem diese gespeichert wurden
                    this.NewCorrectiveActions = [];
                });
        }

        private saveToDatabase(): Deferred {
            return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.Recorditems, this.GetDatabaseEntity())
                .then(() => this.saveSyncEntity());
        }

        private saveToSmartDevice(): Deferred {
            this.IsUnsynced = true;

            let saveDeferred: Deferred;

            if (this.Element && Utils.InArray([Enums.ElementType.Photo, Enums.ElementType.Signature], this.Element.Type) && !!this.Value && !this.IsUpdate) {
                saveDeferred = this.Element.Type === Enums.ElementType.Photo ?
                    this.savePhotoRecorditem() :
                    this.saveSignatureRecorditem();

                saveDeferred = saveDeferred
                    .then(() => this.saveToDatabase())
                    .then(() => this.saveFileSyncEntity());
            } else {
                saveDeferred = this.saveToDatabase();
            }

            return saveDeferred
                .then(() => DAL.Elements.AddRecorditems([this.GetDatabaseEntity()]))
                .then(() => this.moveAdditionalImages())
                .then(() => this.saveComments())
                .then(() => this.saveCorrectiveActions())
                .then(() => this.saveScancodeInfo())
                .then(() => Utils.RemoveTemporaryImages())
                .then(() => {
                    if (this.OnAfterSaved instanceof Function) {
                        this.OnAfterSaved(this.GetRawEntity());
                    }
                });
        }

        private static batchUploadRecorditems(recorditems: Array<Recorditem>): Deferred {
            let defer = $.Deferred().resolve();

            const limitBatchItems = 200;
            const newIDs = {};
            let saveEntities = [];

            for (let i = 0; i < recorditems.length; i++) {
                const item: Recorditem = recorditems[i];
                const syncItem = item.GetSyncEntity();
                saveEntities.push(syncItem);

                if (saveEntities.length >= limitBatchItems) {
                    defer = defer.then($.proxy(Utils.Http.Post, this, 'recorditems', saveEntities))
                        .then((ids) => {
                            $.extend(newIDs, ids);
                        })
                        .fail(function(_response, _state, _error) {
                            throw new Model.Errors.HttpError(_error, _response);
                        });
                    saveEntities = [];
                }
            }

            if (saveEntities.length > 0) {
                defer = defer.then($.proxy(Utils.Http.Post, this, 'recorditems', saveEntities))
                    .then((ids) => {
                        $.extend(newIDs, ids);
                    }, function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
            }

            defer = defer.then(() => Recorditem.onAfterBatchUploadToServer(recorditems, newIDs));

            return defer;
        }

        private static onAfterBatchUploadToServer(recorditems: Array<Model.Recorditem>, newIDs: Dictionary<number>): Deferred {
            const deferred = $.Deferred();
            const rLen = (recorditems || []).length

            if (!rLen) {
                return deferred.resolve().promise();
            }

            let rCnt = -1;

            (function loop() {
                rCnt++;

                if (rCnt >= rLen) {
                    deferred.resolve();
                    return;
                }

                const item = recorditems[rCnt];

                if (newIDs[item.OID]) {
                    item.OnAfterSavedToServer(newIDs[item.OID])
                        .then(() => item.uploadAdditionalFiles())
                        .then(() => item.saveComments())
                        .then(() => item.saveScancodeInfo())
                        .always(loop);
                } else {
                    loop();
                }
            })();

            return deferred.promise();
        }

        private static saveBatchToSmartDevice(recorditems: Array<Recorditem>): Deferred {
            const saveEntities = [];

            for (let recorditem of recorditems) {
                if (!recorditem.Element || !Utils.IsSet(recorditem.Value)) {
                    continue;
                }

                recorditem.IsUnsynced = true;
                saveEntities.push(recorditem.GetDatabaseEntity());
            }

            return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.Recorditems, saveEntities)
                .then(() => this.afterSaveRecorditemBatchToSmartDevice(recorditems, saveEntities));
        }

        private static afterSaveRecorditemBatchToSmartDevice(recorditems: Array<Model.Recorditem>, saveEntities: Model.Recorditem[]): Deferred {
            const syncEntities = [];
            for (let recorditem of recorditems) {
                if (!recorditem.Element || !Utils.IsSet(recorditem.Value)) {
                    continue;
                }

                // TODO Abhängigkeit zu LockState aufbauen
                syncEntities.push(new Model.Synchronisation.RecordItemEntityDescription(
                    recorditem, recorditem.IssueOID
                ));
            }

            return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, syncEntities)
                .then(() => this.copyImages(recorditems))
                .then((fileSyncEntities: Model.Synchronisation.IFileEntityDescription[]) => window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, fileSyncEntities))
                .then(() => DAL.Elements.AddRecorditems(saveEntities))
                .then(() => Recorditem.saveBatchComments(recorditems))
                .then(() => Recorditem.saveBatchScanCodes(recorditems));
        }

        private static copyImages(recorditems: Array<Model.Recorditem>): Deferred {
            const deferred = $.Deferred();
            const syncEntities = [];
            const rLen = (recorditems || []).length;
            let rCnt = -1;

            (function recorditemLoop() {
                rCnt++;

                if (rCnt >= rLen) {
                    deferred.resolve(syncEntities);
                    return;
                }

                const recorditem = recorditems[rCnt];

                if (!recorditem || !recorditem.Element) {
                    recorditemLoop();
                    return;
                }

                let saveDeferred = $.Deferred().resolve().promise();

                if ((recorditem.NewFiles || []).length) {
                    saveDeferred = saveDeferred
                        .then(() => recorditem.copyAdditionalImages())
                        .then((entities: Model.Synchronisation.IEntityDescription[]) => syncEntities.push.apply(syncEntities, entities));
                }

                if (recorditem.GetImageCopy &&
                    (recorditem.Element.Type === Enums.ElementType.Photo || recorditem.Element.Type === Enums.ElementType.Signature)) {
                    saveDeferred = saveDeferred.then(() => {
                        return recorditem.GetImageCopy()
                            .then((blob: Blob) => Utils.WriteBlobToFile(recorditem.Value, blob))
                            .then(() => {
                                syncEntities.push(new Model.Synchronisation.RecordItemFileEntityDescription(
                                    recorditem.Value, Enums.SyncEntityType.RecorditemValueFile,
                                    recorditem, null, recorditem.ModificationTimestamp)
                                );
                            });
                    });
                }

                saveDeferred.always(recorditemLoop);
            })();

            return deferred.promise();
        }

        private static saveBatchComments(recorditems: Array<Model.Recorditem>): Deferred {
            const deferred = $.Deferred();
            const rLen = (recorditems || []).length;
            let rCnt = -1;

            (function recorditemLoop() {
                rCnt++;

                if (rCnt >= rLen) {
                    deferred.resolve();
                    return;
                }

                const recorditem = recorditems[rCnt];

                recorditem.saveComments().always(recorditemLoop);
            })();

            return deferred.promise();
        }

        private static saveBatchScanCodes(recorditems: Array<Model.Recorditem>): Deferred {
            const deferred = $.Deferred();
            const rLen = (recorditems || []).length;
            let rCnt = -1;

            (function recorditemLoop() {
                rCnt++;

                if (rCnt >= rLen) {
                    deferred.resolve();
                    return;
                }

                const recorditem = recorditems[rCnt];

                recorditem.saveScancodeInfo().always(recorditemLoop);
            })();

            return deferred.promise();
        }

        private copyAdditionalImages() {
            const deferred = $.Deferred();
            const instance = this;
            const newFiles = instance.NewFiles || [];
            const oids = Object.keys(newFiles);
            const iLen = oids.length;
            const syncEntities = [];
            let iCnt = -1;

            (function loop() {
                iCnt++;

                if (iCnt >= iLen) {
                    deferred.resolve(syncEntities);
                    return;
                }

                const oid = oids[iCnt];
                const newFile = newFiles[oid];

                if (!newFile || newFile.ModificationType !== Enums.AdditionalImageModificationType.CREATED || !newFile.IsCopy) {
                    loop();
                    return;
                }

                instance.GetAdditionalImageCopy(newFile.OriginalFilename)
                    .then((blob: Blob) => Utils.WriteBlobToFile(newFile.Filename, blob))
                    .then(() => {
                        syncEntities.push(new Model.Synchronisation.RecordItemFileEntityDescription(
                            newFile.Filename, Enums.SyncEntityType.RecorditemAdditionalFile,
                            instance, null, instance.ModificationTimestamp
                        ));
                    })
                    .always(loop);
            })();

            return deferred.promise();
        }

        private uploadRecorditem(): Deferred {
            const instance = this;

            Utils.Spinner.Show();

            return Utils.Http.Put(`recorditems/${this.OID}`, this.GetSyncEntity())
                .then((id: number) => this.OnAfterSavedToServer(id), function(_response, _state, _error) {
                    if (_response && _response.status === 409 && instance.OnAfterError instanceof Function) {
                        return instance.OnAfterError(instance, _response, _state, _error);
                    }

                    throw new Model.Errors.HttpError(_error, _response);
                })
                .then(() => this.uploadAdditionalFiles())
                .then(() => this.saveComments())
                .then(() => this.saveCorrectiveActions())
                .then(() => this.saveScancodeInfo())
                .then(() => {
                    if (this.OnAfterSaved instanceof Function) {
                        this.OnAfterSaved(this);
                    }
                })
                .always(() => Utils.Spinner.HideWithTimeout());
        }

        private static deleteRecorditem(recordItem: Model.Recorditem): Deferred {
            if (!recordItem || !recordItem.ID) {
                return $.Deferred()
                    .reject()
                    .fail(function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
            }

            return Utils.Http.Delete(`recorditems/${recordItem.ID}`);
        }

        private static deleteFromSmartDevice(recordItem: Model.Recorditem): Deferred {
            if (!recordItem) {
                return $.Deferred().reject();
            }

            // IDEE: Synchronisation blockieren, warten bis abgeschlossen um Konflikte zu vermeiden

            return Recorditem.clearCorrectiveActions(recordItem)
                .then(() =>
                    // lade alle recordItem Revisionen
                    Recorditem.loadAllRecorditemRevisions(recordItem.PrecedingOID, [recordItem])
                )
                .then((recorditems: Model.Recorditem[]) => {
                    let processDeferred = Recorditem.DeleteRecorditemsFromDatabase(recorditems, true);

                    // LastRecorditem am Element entfernen
                    // (Aktualisierung auf die richtige Referenz erfolgt bei Synchronisation)
                    if (recordItem.ElementOID) {
                        processDeferred = processDeferred
                            .then(() => {
                                const modifiedElement = DAL.Elements.GetByOID(recordItem.ElementOID);

                                // modifiedElement nur bei Plänen und PP am Raum gesetzt
                                if (!modifiedElement) {
                                    return;
                                }

                                modifiedElement.LastRecorditem = null;

                                if (modifiedElement.Parent && modifiedElement.Parent.Parent &&
                                    modifiedElement.Parent.Parent.Type !== Enums.ElementType.Form) {
                                    return window.Database.GetSingleByKey(Enums.DatabaseStorage.Elements, modifiedElement.OID)
                                        .then(function(element: Model.Elements.Element) {
                                            if (!element || !modifiedElement) {
                                                return;
                                            }

                                            element.LastRecorditem = null;
                                            return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.Elements, element);
                                        });
                                }
                            })
                            .then(null, () => $.Deferred().resolve());  // positives Ergebnis zurückgeben, auch wenn Element nicht aktualisiert werden konnte
                    }

                    if (!recordItem.ID) {
                        // nur Recorditem und alle previous Revisions lokal aus Datenbank löschen, keine SyncEntities nötig
                        return processDeferred;
                    }

                    return processDeferred
                        .then(() => {
                            //  SyncEntity für das Löschen vom Server erstellen
                            const recorditemSyncEntityDescription = new Model.Synchronisation.DeleteRecorditemEntityDescription(recordItem);

                            return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, recorditemSyncEntityDescription);
                        });
                });
        }

        public static DeleteRecorditemsFromDatabase(recorditems: Model.Recorditem[], removeUnsyncedData: boolean): Deferred {
            /**
            * Löscht Erfassungen und zugehörige Kommentare, Dateien und SyncEntities aus der Datenbank
            * @param {array} recorditems - Die zu löschenden Erfassungen
            * @param {boolean} removeUnsyncedData - Legt fest, ob auch unsynchronsierte Daten entfernt werden sollen.
            *                                       Wenn z.B. Elemente gelöscht werden, zugehörige Erfassungen jedoch noch synchronisiert werden sollen.
            */

            let processDeferred: Deferred = $.Deferred().resolve();

            if (!recorditems || !recorditems.length) {
                return processDeferred;
            }

            const syncEntitiesToRemove = new Utils.HashSet();
            const recorditemOIDs = new Utils.HashSet();
            const commentOIDs = new Utils.HashSet();
            const fileNames = new Utils.HashSet();
            const fileNamesToReassign: Dictionary<Issues.Issue> = {};

            for (let i = 0; i < recorditems.length; i++) {
                const tmpRec = recorditems[i];

                // Anhängende Dateien & deren SyncEntities zum Entfernen erfassen
                if (tmpRec.AdditionalFiles && tmpRec.AdditionalFiles.length) {
                    for (let f = 0; f < tmpRec.AdditionalFiles.length; f++) {
                        const tmpFile = tmpRec.AdditionalFiles[f];

                        // Dateien nicht entfernen, wenn Korrekturmaßnahmen diese verwenden
                        if (tmpRec.CorrectiveActions && tmpRec.CorrectiveActions.length) {
                            const actionsUseFile = tmpRec.CorrectiveActions.some((actionIssue: Model.Issues.Issue) => {
                                if (!actionIssue || !actionIssue.Files || !actionIssue.Files.length) {
                                    return false;
                                }

                                const fileInUseOnIssue = actionIssue.Files.some((file: Issues.File) => !file || file.Filename == tmpFile.Filename);

                                if (fileInUseOnIssue) {
                                    // neu zuzuweisende Datei vormerken
                                    fileNamesToReassign[tmpFile.Filename] = actionIssue;
                                }

                                return fileInUseOnIssue;
                            });

                            if (actionsUseFile) {
                                continue;
                            }
                        }

                        if (tmpFile.Filename) {
                            fileNames.put(tmpFile.Filename);
                        }
                    }
                }

                // Anhängende Kommentare & deren SyncEntities erfassen
                if (tmpRec.Comments && tmpRec.Comments.length) {
                    commentOIDs.putObjectKeyFromArray(tmpRec.Comments, 'OID');
                }
            }

            if (removeUnsyncedData && Object.keys(fileNamesToReassign).length) {
                // Sync Entities für Recorditem-Files zu Issue-Files umschreiben
                processDeferred = processDeferred
                    .then(() => window.Database.GetManyByKeys(Enums.DatabaseStorage.SyncEntities, Object.keys(fileNamesToReassign), 'IDX_Filename'))
                    .then((existingSyncEntities: Model.Synchronisation.RecordItemFileEntityDescription[]) => {
                        const entitiesToRemove: string[] = [];
                        const newEntitiesToStore: Model.Synchronisation.IEntityDescription[] = [];

                        for (const entity of existingSyncEntities) {
                            // Type und Zugehörigkeiten zu RecordItem prüfen
                            if (entity.Type !== Enums.SyncEntityType.RecorditemAdditionalFile) {
                                continue;
                            }

                            // Bild zum Vorgang umschreiben und SyncEntity des RecordItems löschen
                            const newSyncEntity = new Model.Synchronisation.IssueFileEntityDescription(entity.Filename, Enums.SyncEntityType.IssueFile, fileNamesToReassign[entity.Filename]);
                            newEntitiesToStore.push(newSyncEntity);
                            entitiesToRemove.push(entity.OID);
                        }

                        return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, newEntitiesToStore)
                            .then(() => {
                                if (entitiesToRemove.length > 0) {
                                    return window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.SyncEntities, entitiesToRemove);
                                }
                            });
                    })
            }

            // Anhängende Dateien & deren SyncEntities löschen
            if (fileNames.size()) {
                if (removeUnsyncedData) {
                    // Datei Sync-Entities nach Filename finden
                    processDeferred = processDeferred
                        .then(() => window.Database.GetManyByKeys(Enums.DatabaseStorage.SyncEntities, fileNames.toArray(), 'IDX_Filename'))
                        .then((existingSyncEntities: Model.Synchronisation.IEntityDescription[]) => {
                            const entitiesToRemove: string[] = [];
                            // Type und Zugehörigkeiten zu RecordItem prüfen, passendes löschen
                            for (const entity of existingSyncEntities) {
                                if (entity.Type !== Enums.SyncEntityType.RecorditemAdditionalFile) {
                                    continue;
                                }

                                entitiesToRemove.push(entity.OID);
                            }

                            if (entitiesToRemove.length > 0) {
                                return window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.SyncEntities, entitiesToRemove);
                            }
                        });
                } else {
                    processDeferred = processDeferred
                        .then(() => window.Database.GetManyByKeys(Enums.DatabaseStorage.SyncEntities, fileNames.toArray(), 'IDX_Filename'))
                        .then((existingSyncEntities: Model.Synchronisation.IFileEntityDescription[]) => {
                            for (const entity of existingSyncEntities) {
                                if (entity.Type !== Enums.SyncEntityType.RecorditemAdditionalFile) {
                                    continue;
                                }

                                // noch unsynchronisierte Daten behalten
                                fileNames.delete(entity.Filename);
                            }
                        });
                }
            }

            // Anhängende Kommentare & deren SyncEntities löschen
            if (commentOIDs.size()) {
                if (removeUnsyncedData) {
                    syncEntitiesToRemove.putSet(commentOIDs);
                } else {
                    processDeferred = processDeferred
                        .then(() => window.Database.Exists(Enums.DatabaseStorage.SyncEntities, commentOIDs.toArray()))
                        .then((existingSyncEntities: string[]) => {
                            // unsynchronisierte Daten behalten
                            existingSyncEntities.forEach((oid: string) => { commentOIDs.delete(oid) });
                        });
                }

                processDeferred = processDeferred
                    .then(() => window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.Comments, commentOIDs.toArray()));
            }

            // Recorditems und SyncEntities löschen
            recorditemOIDs.putObjectKeyFromArray(recorditems, 'OID');

            if (removeUnsyncedData) {
                syncEntitiesToRemove.putSet(recorditemOIDs);
            } else {
                processDeferred = processDeferred
                    .then(() => window.Database.Exists(Enums.DatabaseStorage.SyncEntities, recorditemOIDs.toArray()))
                    .then((existingSyncEntities: string[]) => {
                        // unsynchronisierte Daten behalten
                        existingSyncEntities.forEach((oid: string) => { recorditemOIDs.delete(oid) });
                    });
            }

            // Recorditems löschen
            processDeferred = processDeferred
                .then(() => window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.Recorditems, recorditemOIDs.toArray()));

            if (removeUnsyncedData) {
                // SyncEntities entfernen
                processDeferred = processDeferred
                    .then(() => window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntitiesToRemove.toArray()));
            }

            // Dateien der Erfassungen entfernen
            if (fileNames.size()) {
                processDeferred = processDeferred
                    .then(() => Utils.DeleteFiles(fileNames.toArray()));
            }

            return processDeferred;
        }

        private static loadAllRecorditemRevisions(oid: string, cache?: Model.Recorditem[]): Deferred {
            if (!oid) {
                return $.Deferred().resolve(cache || null);
            }

            return window.Database.GetManyByKeys(Enums.DatabaseStorage.Recorditems, [oid])
                .then((data: Model.Recorditem[]) => {
                    if (!data || !data.length) {
                        return cache || null;
                    }

                    const tmpRecorditem = data[0];
                    cache = cache || [];
                    cache.push(tmpRecorditem);

                    if (!tmpRecorditem.PrecedingOID) {
                        return cache;
                    }

                    // vorangehende Revision laden
                    return Recorditem.loadAllRecorditemRevisions(tmpRecorditem.PrecedingOID, cache);
                });
        }

        private static clearCorrectiveActions(recordItem: Model.Recorditem): Deferred {
            if (!recordItem || !recordItem.CorrectiveActions || !recordItem.CorrectiveActions.length) {
                return $.Deferred().resolve();
            }

            const issueOIDs: string[] = [];
            if (recordItem.CorrectiveActions && recordItem.CorrectiveActions.length) {
                for (let i = 0; i < recordItem.CorrectiveActions.length; i++) {
                    const action = recordItem.CorrectiveActions[i];
                    issueOIDs.push(action.OID);
                }
            }

            return window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, issueOIDs)
                .then((issues: Model.Issues.RawIssue[]) => {
                    if (!issues || !issues.length) {
                        return;
                    }

                    for (let i = 0; i < issues.length; i++) {
                        const tmpIssue = issues[i];
                        tmpIssue.AssignedRecorditemID = null;
                        tmpIssue.AssignedRecorditemOID = null;
                    }

                    return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.Issues, issues);
                });
        }

        private coalesceAdditionalFiles(files: Model.IFileProperties[]): Array<any> {
            const instance = this;

            if (!(files || []).length) {
                return instance.AdditionalFiles;
            }

            if (!(instance.AdditionalFiles || []).length) {
                return files;
            }

            files.forEach(function(file) {
                const fileIndex = Utils.Where(instance.AdditionalFiles, 'Filename', '===', file.Filename, true);

                if (fileIndex >= 0) {
                    instance.AdditionalFiles.splice(fileIndex, 1);
                    instance.AdditionalFiles.push(file);
                }
            });

            return instance.AdditionalFiles;
        }

        public GetSyncEntity(): any {
            const propertiesToIgnore = [
                'CorrectiveActions',
                'IsUpdatable',
                'Comment',
                'Comments',
                'Images',
                'Files',
                'IsHistorical',
                'IsUnsynced',
                'NewFiles',
                'Files',
                'Element',
                'Creator',
                'Editor',
                'NewComments',
                'ExistingCorrectiveActionIssues',
                'OnAfterSaved',
                'OnAfterError',
                'GetSignature',
                'GetImageCopy',
                'GetAdditionalImageCopy'
            ];

            const entity: any = Utils.CloneObject(this, propertiesToIgnore);

            if (entity.AdditionalFiles && entity.AdditionalFiles.length &&
                this.NewFiles && this.NewFiles.length) {
                // neue Dateien bis zum Upload erfolg von der Erfassung entfernen
                for (let fi = 0; fi < this.NewFiles.length; fi++) {
                    const newFile = this.NewFiles[fi];

                    if (newFile.ModificationType !== Enums.AdditionalImageModificationType.CREATED) {
                        continue;
                    }

                    for (let iaf = entity.AdditionalFiles.length - 1; iaf >= 0; iaf--) {
                        const recFile = entity.AdditionalFiles[iaf];

                        if (recFile.OID === newFile.OID ||
                            recFile.Filename === newFile.Filename) {
                            entity.AdditionalFiles.splice(iaf, 1);
                            break;
                        }
                    }
                }
            }

            entity.CreationTimestamp = Utils.DateTime.ToGMTString(entity.CreationTimestamp);
            entity.ModificationTimestamp = Utils.DateTime.ToGMTString(entity.ModificationTimestamp);
            entity.DeadlineTimestamp = Utils.DateTime.ToGMTString(entity.DeadlineTimestamp);

            return entity;
        }

        public static GetPropertiesToIgnoreOnClone(): Array<string> {
            return [
                'CorrectiveActions',
                'IsUpdatable',
                'Comment',
                'Images',
                'Files',
                'IsHistorical',
                'IsUnsynced',
                'NewFiles',
                'Files',
                'Element',
                'Creator',
                'Editor',
                'NewComments',
                'ExistingCorrectiveActionIssues',
                'OnAfterSaved',
                'OnAfterError',
                'GetSignature',
                'GetImageCopy',
                'GetAdditionalImageCopy'
            ];
        }

        public GetDatabaseEntity(): any {
            const propertiesToIgnore = Recorditem.GetPropertiesToIgnoreOnClone();
            const isSmartDevice = Session.IsSmartDeviceApplication;
            const entity: Model.Recorditem = Utils.CloneObject(this, propertiesToIgnore);

            entity.CreationTimestamp = Utils.DateTime.ToGMTString(entity.CreationTimestamp);
            entity.ModificationTimestamp = Utils.DateTime.ToGMTString(entity.ModificationTimestamp);
            entity.DeadlineTimestamp = Utils.DateTime.ToGMTString(entity.DeadlineTimestamp);

            (entity.Comments || []).forEach(function(comment: Model.Comment) {
                if (comment.IsTemporary &&
                    new Date(<string>comment.ModificationTimestamp) < new Date(entity.CreationTimestamp)) {
                    // set same time as recorditem creation-time for new comments
                    comment.CreationTimestamp = entity.CreationTimestamp;
                    comment.ModificationTimestamp = entity.CreationTimestamp;
                } else {
                    comment.CreationTimestamp = Utils.DateTime.ToGMTString(comment.CreationTimestamp);
                    comment.ModificationTimestamp = Utils.DateTime.ToGMTString(comment.ModificationTimestamp);
                }

                // set issueID/OID in app only
                if (isSmartDevice) {
                    if (!comment.IssueID && entity.IssueID) {
                        comment.IssueID = entity.IssueID;
                    }
                    if (!comment.IssueOID && entity.IssueOID) {
                        comment.IssueOID = entity.IssueOID;
                    }
                }
            });

            return entity;
        }

        public SetValue(value: any): Recorditem {
            this.Value = value;
            this.CategoryOID = Utils.Evaluation.Evaluate(<any>this.GetDatabaseEntity());

            return this;
        }

        public SetValueImageMarks(marks: string): Recorditem {
            if (this.ElementType == Enums.ElementType.Photo ||
                this.Element.Type == Enums.ElementType.Photo) {
                const valueInfo = this.AdditionalValueInfo || {};

                if (marks) {
                    valueInfo.Marks = marks;
                } else {
                    delete valueInfo.Marks;
                }

                this.AdditionalValueInfo = Object.keys(valueInfo).length ? valueInfo : null;
            }

            return this;
        }

        public SetExistingComments(comments: Model.Comment[]): Recorditem {
            if ((comments || []).length > 0) {
                this.Comments = comments;
            }

            return this;
        }

        public SetNewComments(comments: Array<Model.Comment>): Recorditem {
            if ((comments || []).length > 0) {
                this.Comments = this.Comments || [];
                this.Comments = this.Comments.concat(comments);

                this.NewComments = comments;
            }

            return this;
        }

        public SetValueImage(image): Recorditem {
            if (!image) {
                return this;
            }

            this.ValueImage = image;

            return this;
        }

        public SetNewFiles(files: Dictionary<any>): Recorditem {
            if (!Utils.HasProperties(files)) {
                return this;
            }

            this.NewFiles = [];
            for (let oid in files) {
                if (!files.hasOwnProperty(oid)) {
                    continue;
                }

                this.NewFiles.push(files[oid]);
            }

            return this;
        }

        public SetOnAfterSavedHandler(handler): Recorditem {
            if (!(handler instanceof Function)) {
                throw new Model.Errors.ArgumentError('handle must be a function');
            }

            this.OnAfterSaved = handler;

            return this;
        }

        public SetOnAfterErrorHandler(handler: (recorditem, response, state, error) => Deferred): Recorditem {
            if (!(handler instanceof Function)) {
                throw new Model.Errors.ArgumentError('handle must be a function');
            }

            this.OnAfterError = handler;

            return this;
        }

        public SetGetSignature(fn: (c: GetSignatureFunc) => void): Recorditem {
            if (!(fn instanceof Function)) {
                throw new Model.Errors.ArgumentError('fn must be a function');
            }

            this.GetSignature = fn;

            return this;
        }

        public SetGetImageCopy(fn: () => Deferred): Recorditem {
            if (!(fn instanceof Function)) {
                throw new Model.Errors.ArgumentError('fn must be a function');
            }

            this.GetImageCopy = fn;

            return this;
        }

        public SetGetAdditionalImageCopy(fn: (path: string) => Deferred): Recorditem {
            if (!(fn instanceof Function)) {
                throw new Model.Errors.ArgumentError('fn must be a function');
            }

            this.GetAdditionalImageCopy = fn;

            return this;
        }

        public SetExistingCorrectiveActionIssues(correctiveActionIssues): Recorditem {
            if ((correctiveActionIssues || []).length > 0) {
                this.ExistingCorrectiveActionIssues = correctiveActionIssues;
            }

            return this;
        }

        public RemoveOnAfterSavedHandler(): void {
            this.OnAfterSaved = null;
        }

        public RemoveOnAfterErrorHandler(): void {
            this.OnAfterError = null;
        }

        public Save(): Deferred {
            const saveDeferred = !Session.IsSmartDeviceApplication ?
                this.uploadRecorditem() :
                this.saveToSmartDevice();

            return saveDeferred
                .fail((err: Model.Errors.HttpError | XMLHttpRequest) => {
                    Utils.Spinner.Hide();

                    // HTTP Request zu HttpError umwandeln
                    if (err instanceof XMLHttpRequest) {
                        err = new Model.Errors.HttpError(null, err);
                    }

                    if (err.httpResponse &&
                        (err.httpResponse.status === Enums.HttpStatusCode.Request_Too_Long ||
                            err.httpResponse.status === Enums.HttpStatusCode.Forbidden)) {
                        throw err;
                    } else {
                        Utils.Message.Show(i18next.t('Recorditem.SaveError.Header'), i18next.t('Recorditem.SaveError.Message'));
                    }
                });
        }

        public static SaveBatch(recorditems: Array<Recorditem>): Deferred {
            if (!Session.IsSmartDeviceApplication) {
                return this.batchUploadRecorditems(recorditems);
            } else {
                return this.saveBatchToSmartDevice(recorditems);
            }
        }

        public static Delete(recordItem: Model.Recorditem): Deferred {
            recordItem.IsDeleted = true;
            return !Session.IsSmartDeviceApplication ?
                this.deleteRecorditem(recordItem) :
                this.deleteFromSmartDevice(recordItem);
        }

        public GetRawEntity(): any {
            const entity: any = this.GetDatabaseEntity();

            if ((this.CorrectiveActions || []).length) {
                entity.CorrectiveActions = $.extend(true, [], this.CorrectiveActions);
            }

            if ((entity.AdditionalFiles || []).length) {
                entity.Images = entity.AdditionalFiles.filter((file) => {
                    return file.MimeType.startsWith('image/');
                });
            }

            return entity;
        }

        public static CreateScanCodeInfo(recorditem: Model.RawRecorditem): Model.Scancodes.ScancodeInfo {
            const scanCodeInfo: Model.Scancodes.ScancodeInfo = {
                ID: `${recorditem.ResubmissionitemOID ? recorditem.ResubmissionitemOID : recorditem.ElementOID}_${recorditem.Value}`,
                Code: <string>recorditem.Value
            };

            if (recorditem.IssueID) {
                scanCodeInfo.IssueID = recorditem.IssueID;
            } else if (recorditem.IssueOID) {
                scanCodeInfo.IssueOID = recorditem.IssueOID;
            }

            if (recorditem.ResubmissionitemOID) {
                scanCodeInfo.ResubmissionitemOID = recorditem.ResubmissionitemOID;
            } else {
                scanCodeInfo.ElementOID = recorditem.ElementOID;
            }

            return scanCodeInfo;
        }

        public static IsRecorditemRecorded(recorditem: Model.Recorditem): boolean {
            if (!recorditem || recorditem.IsDummy ||
                recorditem.Type == Enums.RecorditemType.UNRECORDED) {
                return false;
            }

            let elementType = recorditem.Element ? recorditem.Element.Type : recorditem.ElementType;

            if (!elementType && recorditem.ElementRevisionOID) {
                const element = DAL.Elements.GetByRevisionOID(recorditem.ElementRevisionOID);
                elementType = element ? element.Type : null;
            }

            return (recorditem.Value !== null && typeof recorditem.Value !== 'undefined') ||
                elementType == Enums.ElementType.Checkbox;    // Checkbox darf null erfasst haben
        }
    }
}